html {
    height: 100%;
}

body {
    margin: 0;
    background-color: #0e0815;
    color: white;
    height: 100%;
}

h1, h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 18px;
    text-align: center;
}

#app, .intropage {
    height: 100%;

    .biglogo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        // background-image: url(../img/zig.png);
        // background-position: bottom;
        // background-size: 992px;
        // background-repeat: no-repeat;

        @media (min-width: 992px) {
            background-size: 100%;
        }

        &__content {
            padding: 0 40px 20px 40px;

            img {
                width: 500px;
                max-width: 100%;
            }
        }

    }

}